<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Профиль
          </template>
          <template v-slot:toolbar>
            
          </template>
          <template v-slot:body>
            <button @click="openChangePasswordForm" class="btn btn-success">Сменить пароль</button>

            <b-modal ref="changePasswordModal">
              <template v-slot:modal-title>
                Сменить пароль
              </template>
              <b-form>
                <b-form-group id="input-group-1" label-for="input-1">
                  <template v-slot:label>
                    Текущий пароль
                  </template>
                  <b-form-input
                      type="password"
                      v-model="changePasswordForm.currentPassword"
                      :state="changePasswordFormStates.currentPassword"
                  ></b-form-input>
                  <div class="invalid-feedback">{{ changePasswordFormSaveError.currentPassword }}</div>
                </b-form-group>
                <b-form-group id="input-group-1" label-for="input-1">
                  <template v-slot:label>
                    Новый пароль
                  </template>
                  <b-form-input
                      type="password"
                      v-model="changePasswordForm.newPassword"
                      :state="changePasswordFormStates.newPassword"
                  ></b-form-input>
                  <div class="invalid-feedback">{{ changePasswordFormSaveError.newPassword }}</div>
                </b-form-group>
                <b-form-group id="input-group-1" label-for="input-1">
                  <template v-slot:label>
                    Повторите пароль
                  </template>
                  <b-form-input
                      type="password"
                      v-model="changePasswordForm.repeatNewPassword"
                      :state="changePasswordFormStates.repeatNewPassword"
                  ></b-form-input>
                  <div class="invalid-feedback">{{ changePasswordFormSaveError.repeatNewPassword }}</div>
                </b-form-group>
              </b-form>
              <template v-slot:modal-footer>
                <b-button type="submit" @click="saveNewPassword" variant="primary">{{
                    $t("common.save")
                  }}
                </b-button>
                <b-button type="reset" @click="closeChangePasswordForm" variant="danger">{{
                    $t("common.cancel")
                  }}
                </b-button>
              </template>
            </b-modal>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "ChangePassword",
  components: {
    KTPortlet
  },
  data() {
    return {
      changePasswordForm :{
        currentPassword:'',
        newPassword:'',
        repeatNewPassword:'',
      },
      changePasswordFormStates: {},
      changePasswordFormSaveError: {},
    };
  },
  mounted() {
   
  },
  methods: {
    openChangePasswordForm(){
      this.changePasswordForm = {};
      this.changePasswordFormStates = {};
      this.changePasswordFormSaveError = {};
      this.$refs["changePasswordModal"].show();
    },
    saveNewPassword(evt) {
      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("user/saveNewPassword", this.changePasswordForm)
          .then(function () {
            $this.$refs["changePasswordModal"].hide();

            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.changePasswordFormSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.changePasswordFormStates[i] = false;
              }
            }
          });
    },
    closeChangePasswordForm(evt) {
      evt.preventDefault();
      this.changePasswordForm = {};

      this.$refs["changePasswordModal"].hide();
    },
  }
};
</script>
